.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 90%;
  height: 90%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  cursor: pointer;
}

.modal-body table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
  overflow-y: auto;
  max-height: 100%; /* Altere essa linha */
  height: 100%; /* Adicione essa linha */
}

.modal-body {
  padding: 0;
  margin: 0;
  overflow-y: auto;
  width: 90%;
  height: 70%; /* Adicione essa linha */
}

.modal-body table th,
.modal-body table td {
  border: 1px solid #ddd;
  text-align: left;
  word-break: break-all;
}

.modal-body table th {
  background-color: #f0f0f0;
}

.modal-body table thead {
  font-size: 20px;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.modal-body table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.modal-body table tr:hover {
  background-color: #f2f2f2;
}

.modal-body table tbody {
  overflow-y: auto;
  max-height: 300px; /* ajuste o valor de acordo com a sua necessidade */
}
